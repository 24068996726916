const prod = {
    url: {
        API_HOSTNAME: 'api.openai.com',
        API_PATH: '/v1/chat/completions',
        API_PORT: 443,
        API_PROTOCOL: 'https',
    },
}

const dev = {
    url: {
        API_HOSTNAME: 'api.openai.com',
        API_PATH: '/v1/chat/completions',
        API_PORT: 443,
        API_PROTOCOL: 'https',
    },
}


const localDev = {
    url: {
        API_HOSTNAME: 'localhost',
        API_PATH: '/conversation',
        API_PORT: 3001,
        API_PROTOCOL: 'http',
    },
}

//export const config = process.env.NODE_ENV === 'development' ? localDev : prod
export const config = process.env.NODE_ENV === 'development' ? dev : prod