import {useCallback, useEffect, useState} from 'react'
import {
    applyEdgeChanges,
    applyNodeChanges,
    Background,
    Controls,
    Edge,
    Handle,
    MiniMap,
    Node,
    Position,
    ReactFlow,
} from 'reactflow'
import 'reactflow/dist/style.css'
import {AppState} from '../App'
//@ts-ignore
import dagre from 'dagre'
import './CustomNodes.css'

interface NodeViewProps {
    name: string;
    color: string;
    appState: AppState
}

const initialNodes: Node[] = []

const initialEdges: Edge[] = []

const dagreGraph = new dagre.graphlib.Graph()
dagreGraph.setDefaultEdgeLabel(() => ({}))

const nodeWidth = 172
const nodeHeight = 36

const dragHandleStyle: React.CSSProperties = {
    position: 'absolute',
    top: 0,
    left: 0,
    transform: 'translate(-75%,-75%)',
    display: 'inline-block',
    width: 25,
    height: 25,
    backgroundColor: 'gray',
    borderRadius: '5px',
}

// @ts-ignore
const PromptNode = (({data}) => {
    return (
        <div className="user-prompt-node">
            <Handle type="target" id="b" position={Position.Top}/>
            <span className="custom-drag-handle" style={dragHandleStyle}/>
            <div>
                <div>{data.label}</div>
            </div>
            <Handle type="source" position={Position.Bottom} id="a"/>
            {/*<Handle type="source" position={Position.Bottom} id="b" style={handleStyle}/>*/}
        </div>)
})

// @ts-ignore
const ResponseNode = (({data}) => {
    return (
        <div className="response-node">
            <Handle type="target" id="b" position={Position.Top}/>
            <div>
                <div>{data.label}</div>
            </div>
            <Handle type="source" position={Position.Bottom} id="a"/>
            {/*<Handle type="source" position={Position.Bottom} id="b" style={handleStyle}/>*/}
        </div>)
})

const getLayoutedElements = (nodes: Node[], edges: Edge[], direction = 'TB') => {
    const isHorizontal = direction === 'LR'
    dagreGraph.setGraph({rankdir: direction})

    nodes.forEach((node) => {
        dagreGraph.setNode(node.id, {width: nodeWidth, height: nodeHeight})
    })

    edges.forEach((edge) => {
        dagreGraph.setEdge(edge.source, edge.target)
    })

    dagre.layout(dagreGraph)

    nodes.forEach((node) => {
        const nodeWithPosition = dagreGraph.node(node.id)
        node.targetPosition = isHorizontal ? Position.Left : Position.Top
        node.sourcePosition = isHorizontal ? Position.Right : Position.Bottom

        // We are shifting the dagre node position (anchor=center center) to the top left
        // so it matches the React Flow node anchor point (top left).
        node.position = {
            x: nodeWithPosition.x - nodeWidth / 2,
            y: nodeWithPosition.y - nodeHeight / 2,
        }

        return node
    })

    return {nodes, edges}
}
const edgeType = 'bezier'
const NodeView: React.FC<NodeViewProps> = ({name, color, appState}) => {
    // @ts-ignore
    useEffect(() => {
        const newNodes: Node[] = []
        const newEdges: Edge[] = []
        let x = 0
        let y = 0

        // TODO: process state change
        for (const systemPromptGuid in appState.systemPrompts) {
            const systemPrompt = appState.systemPrompts[systemPromptGuid]
            newNodes.push({
                id: systemPromptGuid,
                data: {label: systemPrompt.promptText},
                position: {x: x, y: y},
                dragHandle: '.custom-drag-handle', // Tells it that only this part is draggable!
                type: 'systemPromptNode',
            })

            for (const userPromptGuid of systemPrompt.promptGuids) {

                newEdges.push({
                    id: `${systemPromptGuid}-${userPromptGuid}`,
                    source: systemPromptGuid,
                    target: userPromptGuid,
                    // label: 'test',
                    type: edgeType,
                })
            }

            x = x + 100
            y = y + 100
        }
        for (const guid in appState.userPrompts) {
            const userPrompt = appState.userPrompts[guid]
            newNodes.push({
                id: guid,
                data: {label: userPrompt.promptText},
                position: {x: x, y: y},
                type: 'promptNode',
            })
            x = x + 100
            y = y + 100
            for (const response of userPrompt.responses) {
                console.log(response)

                newNodes.push({
                    id: response.uuid,
                    data: {label: response.message},
                    position: {x: x, y: y},
                    type: 'responseNode',
                })
                x = x + 100
                // y = y + 100
                newEdges.push({
                    id: `${guid}-${response.uuid}`,
                    source: guid,
                    target: response.uuid,
                    // label: 'test',
                    type: edgeType,
                })
                for (const responsePromptId of response.prompts) {
                    newEdges.push({
                        id: `${responsePromptId}-${response.uuid}`,
                        source: response.uuid,
                        target: responsePromptId,
                        // label: 'test',
                        type: edgeType,
                    })
                }
            }
        }

        // setNodes(newNodes)
        // setEdges(newEdges)

        const eles = getLayoutedElements(newNodes, newEdges)
        setNodes(eles.nodes)
        setEdges(eles.edges)
    }, [])

    const [nodes, setNodes] = useState(initialNodes)
    const [edges, setEdges] = useState(initialEdges)

    const onNodesChange = useCallback(
        // @ts-ignore
        (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
        [],
    )
    const onEdgesChange = useCallback(
        // @ts-ignore
        (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
        [],
    )
    // const nodeTypes = useMemo(() => ({special: CustomNode}), [])
    const nodeTypes = {
        promptNode: PromptNode,
        responseNode: ResponseNode,
        systemPromptNode: PromptNode,
    }

    return (
        <div style={{height: '800px'}}>
            <ReactFlow
                nodeTypes={nodeTypes}
                nodes={nodes}
                onNodesChange={onNodesChange}
                edges={edges}
                onEdgesChange={onEdgesChange}
                proOptions={{hideAttribution: true}} // I don't want to hide this, but the look is broken
            >
                <Background/>
                <Controls/>
                <MiniMap nodeStrokeWidth={3} zoomable pannable/>
            </ReactFlow>
        </div>
    )
}

export default NodeView