import React, {useRef, useState} from 'react'
import {AppState} from '../App'
import {Dropdown} from 'react-bootstrap'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faArrowRight, faCopy, faPlusCircle, faTimes} from '@fortawesome/free-solid-svg-icons'

export interface SystemPrompt {
    promptText: string;
    promptGuids: string[];
}

interface Props {
    prompt: SystemPrompt;
    handleOnCopyClick: OnSystemPromptCopyFn
    handleOnPromptChanged: OnSystemPromptChangedFn
    guid: SystemDirectiveGuid
    appState: AppState,
    systemDirectiveHistory: SystemDirectiveHistory,
    disabled: boolean
    minWidth: string
    handleOnDelete: (prompt: string) => void
}

export interface SystemDirectiveHistory {
    directivesText: SystemDirectiveGuid[]
}

export type SystemDirectiveGuid = string // Not super useful since it doesn't break if we use the wrong one

export type OnSystemPromptChangedFn = (newPromptText: string, guid: string) => void
export type OnSystemPromptCopyFn = (promptText: string) => void

const shouldBeDisabled = (appState: AppState, prompt: SystemPrompt) => prompt.promptGuids.map((promptGuid) => appState.userPrompts[promptGuid]).some(userPrompt => userPrompt.responses.length > 0)

export const SystemDirectiveInput: React.FC<Props> = ({
                                                          prompt,
                                                          handleOnCopyClick,
                                                          handleOnPromptChanged,
                                                          guid,
                                                          appState,
                                                          disabled,
                                                          systemDirectiveHistory,
                                                          minWidth,
                                                          handleOnDelete,
                                                      }) => {
    const [searchText, setSearchText] = useState('')
    const searchRef = useRef<HTMLInputElement>()

    const {promptText} = prompt
    // const [show, setShow] = useState(false)
    const [dontSetNext, setDontSetNext] = useState(false)

    // @ts-ignore
    return (
        <div>System Directive:
            <div className="system-directive-input" style={{display: 'flex', flexDirection: 'row'}}>

                  <textarea
                      style={{width: '100%', minWidth: minWidth, minHeight: '140px'}}
                      placeholder="Enter system directive..."
                      value={promptText}
                      disabled={disabled || shouldBeDisabled(appState, prompt)}
                      onChange={(event => {
                          handleOnPromptChanged(event.target.value, guid)
                      })
                      }
                  />
                <div style={{
                    display: 'flex', flexDirection: 'column',
                }}>
                    <button onClick={() => {
                        // TODO: broken logic for guid, so just send null for parent id, will be ignored below
                        handleOnCopyClick('')
                    }}>
                        <FontAwesomeIcon icon={faPlusCircle}/>
                        <FontAwesomeIcon icon={faArrowRight}/>
                    </button>
                    <button onClick={() => {
                        // TODO: broken logic for guid, so just send null for parent id, will be ignored below
                        handleOnCopyClick(prompt.promptText)
                    }}>
                        <FontAwesomeIcon icon={faCopy}/>
                        <FontAwesomeIcon icon={faArrowRight}/>
                    </button>
                </div>
            </div>
            <div>
                <Dropdown style={{width: '100%'}}
                    // autoClose="outside"
                    // show={show}
                          onToggle={() => {
                              // Select all and focus when clicking button
                              requestAnimationFrame(() => {
                                  searchRef?.current?.focus()
                                  searchRef?.current?.select()
                              })
                              setSearchText('')
                          }}
                >
                    <Dropdown.Toggle variant="dark"
                                     disabled={disabled || shouldBeDisabled(appState, prompt)}
                        // onClick={() => {
                        //     // setShow(!show)
                        // }}
                                     id="dropdown-basic">
                        System Directive History
                    </Dropdown.Toggle>

                    <Dropdown.Menu variant="dark">
                        <>
                            <input
                                // @ts-ignore
                                ref={searchRef}
                                style={{width: '100%'}}
                                type="text" value={searchText}
                                onChange={event => setSearchText(event.target.value)}
                            />
                        </>
                        {systemDirectiveHistory.directivesText.filter((item) =>
                            item.toLowerCase().includes(searchText.toLowerCase()),
                        ).map((historyItem) =>
                            <Dropdown.Item key={historyItem} onClick={(event) => {
                                setDontSetNext(prevState => {
                                    if (!prevState) {
                                        handleOnPromptChanged(historyItem, guid)
                                        // setShow(false)

                                    }
                                    return false
                                })
                            }
                            }>
                                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                    <span style={{flexGrow: 1}}
                                          onClick={(event) => {
                                              event.stopPropagation()
                                              handleOnPromptChanged(historyItem, guid)
                                          }}>{historyItem}</span>
                                    <FontAwesomeIcon icon={faTimes} onClick={(event) => {
                                        setDontSetNext(true)
                                        event.stopPropagation()
                                        handleOnDelete(historyItem)
                                    }}/>
                                </div>
                            </Dropdown.Item>,
                        )}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </div>
    )
}
