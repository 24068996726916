import React, {useRef, useState} from 'react'
import {Button, Form, Modal} from 'react-bootstrap'

// @ts-ignore
const RenameModal = ({originalFilename, show, handleOnClose, handleOnRename}) => {
    const [filename, setFilename] = useState(originalFilename)
    const inputRef = useRef<HTMLInputElement>(null)
    const selectAllText = () => {
        inputRef?.current?.select()
    }

    // TODO: what if we dont change the filename!!!
    const saveChanges = () => {
        handleOnRename(filename)
        handleOnClose()
    }
    return (
        <Modal onHide={handleOnClose} show={show} onShow={() => {
            selectAllText()
        }
        }>
            <Modal.Header closeButton>
                <Modal.Title>Rename</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {/*Taking the form out, means enter wont submit and refresh page, probably a more correct way to do this though*/}
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Filename:</Form.Label>
                    <Form.Control
                        ref={inputRef}
                        onChange={event => {
                            console.log('pressing')
                            setFilename(event.target.value)
                        }}
                        onKeyDown={event => {
                            if (event.key === 'Enter') {
                                saveChanges()
                            }
                        }
                        }
                        defaultValue={originalFilename}
                        type="text" placeholder="Enter filename"
                    />
                    <Form.Text className="text-muted">
                        Enter the name to change to.
                    </Form.Text>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleOnClose}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={() => {
                    saveChanges()
                }}>
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default RenameModal
