import React, {useEffect, useRef} from 'react'

interface WakeLockManagerProps {
    wakelockOn: boolean;
}

const WakeLockManager: React.FC<WakeLockManagerProps> = ({wakelockOn}) => {
    const wakeLockRef = useRef(null)

    useEffect(() => {
        if (wakelockOn) {
            // request a wake lock
            // @ts-ignore
            navigator.wakeLock?.request('screen')
                // @ts-ignore
                .then((wakeLock) => {
                    wakeLockRef.current = wakeLock
                    console.log('Wake Lock acquired')
                })
                .catch((err: Error) => {
                    console.error(`${err.name}, ${err.message}`)
                })
        } else {
            // release the wake lock
            // @ts-ignore
            wakeLockRef.current?.release()
            wakeLockRef.current = null
            console.log('Wake Lock released')
        }

        return () => {
            // release the wake lock when the component unmounts
            // @ts-ignore
            wakeLockRef.current?.release()
            wakeLockRef.current = null
            console.log('Wake Lock released')
        }
    }, [wakelockOn])

    return null
}

export default WakeLockManager
