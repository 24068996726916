export class Usage {
    promptTokens: number;
    completionTokens: number;
    totalTokens: number;
    constructor(promptTokens: number, completionTokens: number, totalTokens: number) {
        this.promptTokens = promptTokens;
        this.completionTokens = completionTokens;
        this.totalTokens = totalTokens
    }

    // TODO: something with copying was breaking this, not worth digging into too wildly
    // get totalTokens(): number {
    //     return this.promptTokens + this.completionTokens;
    // }
}