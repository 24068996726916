import 'bootstrap/dist/css/bootstrap.min.css'
import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'

import * as Sentry from '@sentry/react'
import {BrowserTracing} from '@sentry/tracing'
import {Provider} from 'react-redux'
import store from './redux/store'

Sentry.init({
    dsn: 'https://bb962eb3f42b4b59a910f476ee49df16@o4504809366159360.ingest.sentry.io/4504809370943488',
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
})

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
)


root.render(
    <React.StrictMode>
        <Provider store={store}>
            <App/>
        </Provider>
    </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
