import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCopy} from "@fortawesome/free-regular-svg-icons";

interface IProps {
    content: string; // The content that will be copied to clipboard
}

const CodeCopyButton: React.FC<IProps> = ({content}) => {
    const handleClick = () => {
        navigator.clipboard.writeText(content);
    };

    // const buttonStyle = ;
    // @ts-ignore
    return (
        <button style={{
            // backgroundColor: 'transparent',
            // backgroundColor: '#555',
            // border: 'none',
            color: 'white',
            opacity: .4,
            // TODO: no idea why these are not working
            // @ts-ignore
            ':hover': {
                color: 'red',
                backgroundColor: 'green'
            },
            ':active': {
                color: 'red',
                backgroundColor: 'green'
            }
        }} onClick={handleClick}>
            <FontAwesomeIcon icon={faCopy}/>
        </button>
    );
};

export default CodeCopyButton;