import React from 'react'
import {decrement, increment} from '../redux/counterSlice'
import {RootState} from '../redux/store'
import {useAppDispatch, useAppSelector} from '../redux/hooks'

export function Counter() {
    const count = useAppSelector((state: RootState) => {
        return state.counter.value
    })
    const dispatch = useAppDispatch()

    return (
        <div>
            <div>
                <button
                    aria-label="Increment value"
                    onClick={() => dispatch(increment())}
                >
                    Increment
                </button>
                <span>{count}</span>
                <button
                    aria-label="Decrement value"
                    onClick={() => dispatch(decrement())}
                >
                    Decrement
                </button>
            </div>
        </div>
    )
}
