import React, {ReactNode, useRef, useState} from 'react'
import ReactMarkdown from 'react-markdown'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faArrowRight,
    faComment,
    faCommentSlash,
    faCopy,
    faMicrophone,
    faPlusCircle,
} from '@fortawesome/free-solid-svg-icons'
import Alert from 'react-bootstrap/Alert'
import {EventStreamContentType, fetchEventSource} from '@microsoft/fetch-event-source'
import {v4 as uuidv4} from 'uuid'
import {HttpError} from './data/HttpError'
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter'
import {dark} from 'react-syntax-highlighter/dist/esm/styles/prism'
import CodeCopyButton from './components/CodeCopyButton'
import GPT3Tokenizer from 'gpt3-tokenizer'

import {Usage} from './data/Usage'
import UsageDetails from './components/UsageDetails'
import {Message, Role} from './data/Message'
import {AppState} from './App'
import {SystemDirectiveGuid} from './components/SystemDirectiveInput'
import {config} from './Constants'
//@ts-ignore
import {debounce} from 'lodash'

const MODEL = 'gpt-3.5-turbo'

const tokenizer = new GPT3Tokenizer({type: 'gpt3'}) // or 'codex'

export function createSystemMessage(content: string): Message[] {
    return addToMessages([], Role.System, content)
}

function addUserMessage(messages: Message[], content: string): Message[] {
    return addToMessages(messages, Role.User, content)
}

function addAssistantMessage(messages: Message[], content: string): Message[] {
    return addToMessages(messages, Role.Assistant, content)
}

function addToMessages(messages: Message[], role: Role, content: string): Message[] {
    const message = new Message(role, content)
    return [...messages, message]
}


interface PromptBlockProps {
    apiKey: string;
    appState: AppState;
    debug: boolean;
    guid: string
    handleChangePromptText: (guid: string, newText: string) => void;
    handleCopyPrompt: (promptText: string, systemPromptGuid: SystemDirectiveGuid, responseIndex?: number, promptGuid?: string) => void;
    handleError: (error: HttpError) => void;
    handleOnSubmitClick: () => void;
    handleResponsePiece: (response: any, partialMessage: string, responseGuid: string, usage: Usage, responseIndex?: number) => void;
    handleShowOptions: () => void;
    messages: Array<any>;
    responseIndex?: number;
    systemPromptGuid: SystemDirectiveGuid;
    minWidth: string
    handleIsPlaying: (isPlaying: boolean) => void,
    isPlaying: boolean
}

function GetKeyAlert(handleShowOptions: () => void) {
    return <div>Try getting a <Alert.Link href="https://platform.openai.com/account/api-keys">new
        API key</Alert.Link>,
        then pasting it into the <Alert.Link onClick={handleShowOptions}>options
            dialog</Alert.Link>.</div>
}


interface Props {
    children: React.ReactNode;
    inline: boolean
}

const CodeMarkdown: React.FC<Props> = ({children, inline}) => {
    return (
        <>
            {inline ? (
                <code>{children}</code>
            ) : (
                <div style={{minWidth: '400px', position: 'relative'}}>
                    <SyntaxHighlighter
                        children={String(children).replace(/\n$/, '')}
                        style={dark}
                        language="python"
                        PreTag="div"
                    />
                    <div style={{position: 'absolute', top: '0', right: '0', padding: '10px'}}>
                        <CodeCopyButton content={String(children)}/>
                    </div>
                </div>
            )}
        </>
    )
}

// @ts-ignore
const PromptBlock: React.FC<PromptBlockProps> = ({
                                                     guid,
                                                     systemPromptGuid,
                                                     handleOnSubmitClick,
                                                     appState,
                                                     messages,
                                                     handleCopyPrompt,
                                                     handleChangePromptText,
                                                     debug,
                                                     responseIndex,
                                                     handleError,
                                                     handleShowOptions,
                                                     apiKey,
                                                     handleResponsePiece,
                                                     minWidth,
                                                     handleIsPlaying,
                                                     isPlaying,
                                                 }) => {
    const [errors, setErrors] = useState<HttpError[]>([])
    const [disabled, setDisabled] = useState(false)
    const [debugSendMessageChain, setDebugSendMessageChain] = useState([])

    const speak = (text: string) => {
        const utterance = new SpeechSynthesisUtterance(text)
        utterance.rate = 0.8 // Set the rate to half of the normal speaking speed (default is 1)
        utterance.addEventListener('end', event => {
            // setIsPlaying(false)
            handleIsPlaying(false)
        })
        utterance.addEventListener('error', event => {
            // setIsPlaying(false)
            handleIsPlaying(false)
        })
        speechSynthesis.speak(utterance)
        // setIsPlaying(true)
        handleIsPlaying(true)
    }
    const promptTextElement = useRef<HTMLTextAreaElement>(null)
    const handleChange = debounce((event: React.ChangeEvent<HTMLInputElement>) => {
        promptTextElement.current && handleChangePromptText(guid, promptTextElement.current?.value)
    }, 300)

    // @ts-ignore
    const handlePromptChange = (event) => {
        // appState.userPrompts[guid].promptText = event.target.value
        // handleChangePromptText(guid, event.target.value)
        handleChange(event)
        // sendAppStateUpdate()
    }

    const streamingResponse = () => {
        if (promptTextElement.current) {
            const prompt = promptTextElement.current.value //appState.userPrompts[guid].promptText

            let sendMessageChain = addUserMessage(messages, prompt)
            console.log('Send message chain:')
            console.dir(sendMessageChain)
            const requestData = {
                model: MODEL, messages: sendMessageChain, stream: true,
            }
            // @ts-ignore
            setDebugSendMessageChain(JSON.stringify(sendMessageChain))
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${apiKey}`,
            }

            let partialMessage = ''
            let responseGuid = uuidv4() // A guid so we can update the partial responses easily
            const usage = new Usage(0, 0, 0)
            // TODO: add up all tokens from message chain
            sendMessageChain.forEach((item) => {
                usage.promptTokens += tokenizer.encode(item.content).bpe.length
            })
            // TODO: cancel retries?
            fetchEventSource(`${config.url.API_PROTOCOL}://${config.url.API_HOSTNAME}:${config.url.API_PORT}${config.url.API_PATH}`, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(requestData),
                async onopen(response) {
                    console.dir(response)
                    if (response.ok && response.headers.get('content-type') === EventStreamContentType) {
                        return // everything's good
                    } else if (response.status >= 400 && response.status < 500) {
                        // client-side errors are usually non-retriable:
                        // throw new FatalError();
                        const error = new HttpError(response)
                        setErrors(prevErrors => [...prevErrors, error])
                        handleError(error)
                    } else {
                        // throw new RetriableError();
                    }
                },
                onmessage(msg) {
                    // console.dir(msg)
                    // if the server emits an error message, throw an exception
                    // so it gets handled by the onerror callback below:
                    if (msg.event === 'FatalError') { // TODO: not sure if this is real? or just from sample code
                        // throw new FatalError(msg.data);
                    } else {
                        if (msg.data === '[DONE]') {
                            console.log('done')
                        } else {
                            const eventData = JSON.parse(msg.data)
                            partialMessage += eventData.choices[0].delta.content || ''
                            usage.completionTokens = tokenizer.encode(partialMessage).bpe.length
                            usage.totalTokens = usage.promptTokens + usage.completionTokens
                            handleResponsePiece(guid, partialMessage, responseGuid, usage, responseIndex)
                            /**
                             *   "usage": {
                             *     "prompt_tokens": 9,
                             *     "completion_tokens": 12,
                             *     "total_tokens": 21
                             *   }
                             */
                        }
                    }
                },
                onclose() {
                    console.dir('closed')
                    console.log(partialMessage)
                    // TODO: usage is missing from streaming
                    usage.completionTokens = tokenizer.encode(partialMessage).bpe.length // Final
                    handleResponsePiece(guid, partialMessage, responseGuid, usage, responseIndex)
                    // const newResponse = new Response(partialMessage, {} )
                    // handleAddNewResponse(guid, newResponse)
                },
                onerror(err) {
                    console.dir(err)
                    setErrors(prevErrors => [...prevErrors, err])
                    handleError(err)
                    // Can also rethrow a different error to retry, with intervals
                    throw err // rethrow to stop the operation
                },
            })
        }

        // TODO: do set errors!
    }
    const handleButtonClick = () => {
        // Quick disabled once we send anything
        setDisabled(true) // Don't want people to break the graph by changing prompts
        handleOnSubmitClick()
        streamingResponse()
    }
    // @ts-ignore
    const handleShiftPlusEnter = (event) => {
        if (event.key === 'Enter' && event.shiftKey) {
            // Shift + Enter was pressed
            handleButtonClick()
            event.preventDefault()
        }
    }

    const shouldBeDisabled = () => appState.userPrompts[guid].responses?.length > 0 || disabled
    const CodeMarkdownFn = (inline: boolean, children: ReactNode) =>
        <CodeMarkdown inline={inline} children={children}/>

    // @ts-ignore
    // @ts-ignore
    return (<div style={{background: 'black', padding: '10px', flexGrow: 1, minWidth: minWidth}}>
        <div style={{
            display: 'flex', flexDirection: 'row',
        }}>

          <textarea
              // value={}
              ref={promptTextElement}
              defaultValue={appState.userPrompts[guid].promptText}
              onChange={handlePromptChange}
              onKeyDown={handleShiftPlusEnter}
              placeholder="Enter prompt..."
              rows={4}
              disabled={shouldBeDisabled()}

              // defaultValue={defaultPrompt}
              style={{width: '100%', minWidth: '150px'}}
          />
            <div style={{
                display: 'flex', flexDirection: 'column',
            }}>
                <button onClick={() => {
                    // TODO: broken logic for guid, so just send null for parent id, will be ignored below
                    handleCopyPrompt('', systemPromptGuid, responseIndex)
                }}>
                    <FontAwesomeIcon icon={faPlusCircle}/>
                    <FontAwesomeIcon icon={faArrowRight}/>
                </button>
                <button onClick={() => {
                    // TODO: broken logic for guid, so just send null for parent id, will be ignored below
                    handleCopyPrompt(appState.userPrompts[guid].promptText, systemPromptGuid, responseIndex)
                }}>
                    <FontAwesomeIcon icon={faCopy}/>
                    <FontAwesomeIcon icon={faArrowRight}/>
                </button>
            </div>
        </div>
        {apiKey.length < 10 &&
            <Alert variant="danger" dismissible>
                Your API key seems incorrect.
                {GetKeyAlert(handleShowOptions)}
            </Alert>}

        <button onClick={handleButtonClick}>Submit</button>

        {!shouldBeDisabled() ? <button onClick={() => {
            // @ts-ignore
            const recognition = new window.webkitSpeechRecognition()

            // @ts-ignore
            recognition.onresult = (event) => {
                const transcript = event.results[0][0].transcript
                handleChangePromptText(guid, transcript)
            }

            recognition.start()
        }
        }>Start Listening <FontAwesomeIcon icon={faMicrophone}/></button> : null}
        {debug ? (<div>
            <div>
                Debug Nodes:
                {JSON.stringify(appState.userPrompts[guid], null, 4)}
                <br/>
                <br/>
                Debug messages:
                {JSON.stringify(messages, null, 4)}
            </div>
            <div>
                {/*DebugState:{debugState}<br/>*/}
                {/*{debugSendMessageChain.map((message, index) =>*/}
                {/*    (<div>{message}</div>)*/}
                {/*)}*/}
                Send message chain:{debugSendMessageChain}
            </div>
        </div>) : (<div/>)}
        <div>
            {
                errors.map((error: HttpError, index: number) => (
                    <Alert key={index} variant="danger" onClose={() => {
                        const shortenedErrors = [...errors]
                        shortenedErrors.splice(index, 1)
                        setErrors(shortenedErrors)
                    }
                    } dismissible>
                        <div>
                            {error.response?.status === 400 ?
                                <div>This thread is too long. Try a new thread. This behavior will be improved in the
                                    future.</div> : null /* Too long */}
                            {error.response?.status === 401 ?
                                GetKeyAlert(handleShowOptions) : null /* Bad key */}
                            {error.response?.status === 500 ? <div>Open AI may be down. Check the status <Alert.Link
                                href="https://status.openai.com/">here</Alert.Link></div> : null /* Open AI down*/}
                            {((error.response?.status !== 500) && (error.response?.status !== 400) && (error.response?.status !== 401)) &&
                                // @ts-ignore
                                (<div>{error.message}</div>) /* I don't know*/}
                        </div>
                    </Alert>
                ))}
        </div>
        <div style={{display: 'flex', flexDirection: 'row'}}>
            {appState.userPrompts[guid].responses?.map(
                // @ts-ignore
                (response, responseIndex) => <div
                    key={responseIndex}
                    style={{
                        minWidth: '400px',
                        background: responseIndex % 2 === 0 ? '#333' : '#222',
                        padding: '10px',
                        border: '1px solid #000',
                        borderRadius: '4px',
                    }}
                >

                    <div style={{
                        minHeight: '100px', // So copy and speak don't overlap
                        minWidth: '400px',
                        maxWidth: '600px',
                        position: 'relative', // Needed for absolute position of below
                    }}>
                        <ReactMarkdown
                            children={response?.message.replaceAll('\n', '  \n')}
                            components={{
                                // TODO: fix sonar lint
                                code({node, inline, className, children, ...props}) {
                                    return CodeMarkdownFn(inline || false, children)
                                },
                            }}
                        />
                        <div style={{
                            position: 'absolute',
                            top: '0',
                            right: '0',
                        }}><CodeCopyButton content={response?.message}/>
                        </div>
                        <div style={{
                            position: 'absolute',
                            bottom: '0',
                            right: '0',
                        }}>{
                            isPlaying ? <button
                                style={{
                                    opacity: .4,
                                    maxWidth: '56px',
                                }}
                                onClick={() => {
                                    speechSynthesis.cancel()
                                    handleIsPlaying(false)
                                    // setIsPlaying(false)
                                }}><FontAwesomeIcon icon={faCommentSlash}/></button> : <button style={{
                                opacity: .4,
                                maxWidth: '56px',
                            }}
                                                                                               onClick={() => {
                                                                                                   speak(response?.message)
                                                                                               }}><FontAwesomeIcon
                                icon={faComment}/></button>
                        }
                        </div>

                    </div>


                    <UsageDetails usage={response.usage}/>
                    {debug ? <div>
                        Tokens:
                        {JSON.stringify(response.usage, null, 4)}
                        <br/>
                    </div> : <div/>}
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        {response.prompts?.map((promptGuid: string, promptIndex: number) => {
                            // console.log(promptGuid)
                            let aMessage = appState.userPrompts[guid].responses[responseIndex]?.message
                            // console.log("Message for child: ", aMessage)
                            let prompt = appState.userPrompts[guid].promptText
                            // console.log("Prompt for child: ", prompt)
                            let builtMessageChain = addAssistantMessage(addUserMessage(messages, prompt), aMessage)
                            // console.log("Chain for child: ", JSON.stringify(builtMessageChain))
                            // return (<div></div>)
                            return <PromptBlock
                                minWidth={'400px'}
                                key={promptGuid}
                                guid={promptGuid}
                                appState={appState}
                                isPlaying={isPlaying}
                                handleOnSubmitClick={handleOnSubmitClick}
                                handleIsPlaying={handleIsPlaying}
                                // TODO: convert guids to a type?
                                handleCopyPrompt={(promptText: string, systemPromptGuid: string, responseIndex?: number, parentGuid?: string) => {
                                    // TODO: I screwed up logic here
                                    console.log('Going to log this parent:', parentGuid, ' guid: ' + guid)
                                    handleCopyPrompt(promptText, systemPromptGuid, responseIndex, parentGuid ? parentGuid : guid)
                                    // console.log("Parent:" + guid)
                                    // console.log("Mine:" + promptGuid)
                                }
                                }
                                // onSameGraphClicked={() => handleSameGraphClicked(prompt, promptIndex)}
                                messages={builtMessageChain}
                                // onSameGraphClicked={onSameGraphClicked}
                                // handleAddNewResponse={handleAddNewResponse} // GUID, newResponse
                                handleChangePromptText={handleChangePromptText} // GUID, newText
                                responseIndex={responseIndex}
                                // @ts-ignore
                                promptIndex={promptIndex}
                                debug={debug}
                                handleError={handleError}
                                handleShowOptions={handleShowOptions}
                                apiKey={apiKey} // TODO: use context
                                handleResponsePiece={handleResponsePiece}
                            />
                        })}
                    </div>
                </div>)}
        </div>
    </div>)
}

// PromptBlock.propTypes = {
//     guid: PropTypes.string,
//     appState: PropTypes.object,
//     messages: PropTypes.array,
//     handleCopyPrompt: PropTypes.func,
//     handleAddNewResponse: PropTypes.func,
//     handleChangePromptText: PropTypes.func,
//     debug: PropTypes.bool,
//     responseIndex: PropTypes.number,
//     handleError: PropTypes.func,
//     handleShowOptions: PropTypes.func,
//     apiKey: PropTypes.string,
//     handleResponsePiece: PropTypes.func,
// };

export default PromptBlock
