export class Response {
    message: string
    usage: any
    prompts: string[]

    // TODO: don't use any
    uuid: string
    constructor(message: string, usage: any, prompts: string[], uuid: string) {
        this.message = message
        this.usage = usage
        this.prompts = prompts
        this.uuid = uuid;
    }
}