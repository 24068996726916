import React, {useState} from "react";
import {Button, Dropdown} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil} from "@fortawesome/free-solid-svg-icons";

interface Props {
    selectedFileName: string;
    possibleFileNames: string[];
    handleOnButtonClicked: (filename: string) => void;
    handleOnDropdownItemClicked: (filename: string) => void;
}

const FilePicker: React.FC<Props> = ({
                                         selectedFileName,
                                         possibleFileNames,
                                         handleOnButtonClicked,
                                         handleOnDropdownItemClicked,
                                     }) => {
    const [showDropdown, setShowDropdown] = useState(false);

    const handleButtonClick = () => handleOnButtonClicked(selectedFileName);

    const handleDropdownItemClicked = (filename: string) => {
        handleOnDropdownItemClicked(filename);
        setShowDropdown(false);
    };

    return (
        <>
            <Dropdown>
                <Dropdown.Toggle
                    style={{
                        minWidth: '150px'
                    }}
                    variant="secondary" id="dropdown-basic">{selectedFileName}</Dropdown.Toggle>
                <Dropdown.Menu show={showDropdown} variant="dark">
                    {possibleFileNames.map((filename) => (
                        <Dropdown.Item
                            key={filename}
                            onClick={() => handleDropdownItemClicked(filename)}
                        >
                            {filename}
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
            <Button onClick={handleButtonClick} variant={"dark"}>
                <FontAwesomeIcon icon={faPencil}/>
            </Button>
        </>
    );
};

export default FilePicker;
