import React, {useState} from 'react'
import {Alert} from 'react-bootstrap'

function TipRequest() {
    const [visible, setVisible] = useState(true)

    const handleClear = () => {
        setVisible(false)
    }

    return (
        <div style={{position: 'fixed', bottom: 20, right: 20}}>
            {visible && (
                <Alert variant="primary" onClose={handleClear} dismissible>
                    Would you like to <Alert.Link href="https://PayPal.Me/mikedg0">leave a tip for Mike?</Alert.Link>
                </Alert>
            )}
        </div>
    )
}

export default TipRequest