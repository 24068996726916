import {createSlice} from '@reduxjs/toolkit'
import type {RootState} from './store'

// Define a type for the slice state
interface ToolbarState {
    optionsDialogShowing: boolean
}

// Define the initial state using that type
const initialState: ToolbarState = {
    optionsDialogShowing: false,
}

export const toolbarSlice = createSlice({
    name: 'toolbar',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        showOptionsDialog: (state) => {
            state.optionsDialogShowing = true
        },
        hideOptionsDialog: (state) => {
            state.optionsDialogShowing = false
        },
    },
})

export const {showOptionsDialog, hideOptionsDialog} = toolbarSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectOptionsVisible = (state: RootState) => state.toolbar.optionsDialogShowing

export default toolbarSlice.reducer

