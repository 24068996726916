import './App.css'
import PromptBlock, {createSystemMessage} from './PromptBlock'
import React, {useEffect, useState} from 'react'
import {v4 as uuidv4} from 'uuid'
import OptionsDialog from './OptionsDialog'
import SupportButton from './components/SupportButton'
import TipRequest from './components/TipRequest'
import {Response} from './data/Response'
import {Usage} from './data/Usage'
import {SystemDirectiveHistory, SystemDirectiveInput, SystemPrompt} from './components/SystemDirectiveInput'
import {HttpError} from './data/HttpError'
import RenameModal from './components/RenameModal'
import FilePicker from './components/FilePicker'
import {Button, Container, Navbar} from 'react-bootstrap'
import update from 'immutability-helper'
import NodeView from './components/NodeView'
import WakeLock from './components/WakeLock'
import {Counter} from './components/Counter'
import {useAppDispatch, useAppSelector} from './redux/hooks'
import {hideOptionsDialog, showOptionsDialog} from './redux/toolbarSlice'
import {RootState} from './redux/store'

// const dbName = 'db1'

// initiate jsstore connection
// var connection = new JsStore.Connection()
//
// // step1 - create database schema
// var tblProduct = {
//     name: 'Product',
//     columns: {
//         // Here "Id" is name of column
//         Id: {primaryKey: true, autoIncrement: true},
//         ItemName: {notNull: true, dataType: 'string'},
//         Price: {notNull: true, dataType: 'number'},
//         Quantity: {notNull: true, dataType: 'number'},
//     },
// }
//
// var tblOrder = {
//     name: 'Order',
//     columns: {
//         // Here "OrderId" is name of column
//         OrderId: {primaryKey: true, autoIncrement: true},
//     },
// }

// var db = {
//     name: dbName,
//     tables: [tblProduct, tblOrder],
// }


// Basically a feature flag for something that should only be run locally
const localOnly = () => window.location.hostname === 'localhost'

/**
 * Keys for local storage
 */
const SAVE_KEY = 'saves'
const API_KEY = 'apiKey'
const SYSTEM_DIRECTIVE_HISTORY_KEY = 'systemDirectiveHistory'
const LAST_OPEN_FILE_NAME_KEY = 'lastOpenFileName'

const DEFAULT_SYSTEM_DIRECTIVE = 'You are ChatGPT, a large language model trained by OpenAI. Answer as concisely as possible.'


const NEW_APP_STATE = (): AppState => {
    const newSystemPrompt = makeNewSystemPrompt(DEFAULT_SYSTEM_DIRECTIVE)
    const newSystemPromptGuid = uuidv4()
    const newUserPrompt = makeNewPrompt()
    const newUserPromptGuid = uuidv4()

    newSystemPrompt.promptGuids[0] = newUserPromptGuid

    return {
        systemPrompts: {[newSystemPromptGuid]: newSystemPrompt},
        userPrompts: {[newUserPromptGuid]: newUserPrompt},
    }
}
export const makeNewSystemPrompt = (promptText: string): SystemPrompt => {
    return {
        promptText: promptText,
        promptGuids: [],
    }
}

const makeNewPrompt = () => {
    return {
        promptText: '',
        responses: [],
    }
}

export class AppState {
    systemPrompts: SystemPromptMap = {}
    userPrompts: NodeMap = {}
}

interface NodeMap {
    [guid: string]: Node
}

interface SystemPromptMap {
    [guid: string]: SystemPrompt
}

export class Node {
    promptText: string = ''
    responses: Response[] = []
}

interface BooleanMap {
    [guid: string]: boolean
}

type FileName = string

interface FilesMap {
    [name: FileName]: AppState
}

const datedFile = () => {
    const currentDate = new Date()
    const year = currentDate.getFullYear().toString()
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0')
    const day = currentDate.getDate().toString().padStart(2, '0')
    const hours = currentDate.getHours().toString().padStart(2, '0')
    const minutes = currentDate.getMinutes().toString().padStart(2, '0')
    const seconds = currentDate.getSeconds().toString().padStart(2, '0')
    const currentDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
    return currentDateTime
}

class ChildPromptGuidSet extends Set<PromptGuid> {

}

export type PromptGuid = string

function App() {
    const dispatch = useAppDispatch()

    // @ts-ignore
    // useEffect(() => {
    //     async function fetchData() {
    //         initJsStore()
    //         const ss = new StudentService()
    //         const students = await ss.getStudents()
    //         console.log(students)
    //         await ss.addStudent({
    //                 name: 'this.refs.name.value',
    //                 gender: 'this.refs.gender.value',
    //                 country: 'this.refs.country.value',
    //                 city: 'this.refs.city.value',
    //             },
    //         )
    //     }
    //
    //     fetchData()
    // }, [])

// since connection is opened now, you can call apis.

    const [showRename, setShowRename] = useState(false)

    const [appState, setAppState] = useState<AppState>(NEW_APP_STATE())
    const [selectedFile, setSelectedFile] = useState('')
    const [fileName, setFileName] = useState<string>()
    // const [fileName, setFileName] = useState(datedFile())
    // const [possibleFiles, setPossibleFiles] = useState([])
    const [apiKey, setApiKey] = useState(localStorage.getItem(API_KEY) || '')

    // const [lastConversation, setLastConversation] = useState<AppState>()
    const [headerHeight, setHeaderHeight] = useState(0)

    const [isDragging, setIsDragging] = useState(false)
    const [startX, setStartX] = useState(0)
    const [startY, setStartY] = useState(0)
    const [scrollLeft, setScrollLeft] = useState(0)
    const [scrollTop, setScrollTop] = useState(0)

    const [isPlaying, setIsPlaying] = useState(false)

    const [files, setFiles] = useState<FilesMap>({})
    // useState(JSON.parse(localStorage.getItem(FILES_KEY) as string)
    // || {}) // Get files or nothing!

    const [disabledSystemDirectives, setDisabledSystemDirectives] = useState<BooleanMap>({})
    const [systemDirectiveHistoru, setSystemDirectiveHistory] = useState<SystemDirectiveHistory>(
        JSON.parse(localStorage.getItem(SYSTEM_DIRECTIVE_HISTORY_KEY) as string)
        || {directivesText: [DEFAULT_SYSTEM_DIRECTIVE]})

    const [showGraph, setShowGraph] = useState(false)

    const [spaceHeldDown, setSpaceHeldDown] = useState(false)
    const [cursor, setCursor] = useState('auto')

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.code === 'Space' && event.target === document.body) {
                const activeElement = document.activeElement as HTMLElement
                if (!activeElement || (activeElement && activeElement.tagName !== 'INPUT' && activeElement.tagName !== 'TEXTAREA')) {
                    event.preventDefault()
                    setSpaceHeldDown(true)
                    setCursor('grabbing')
                }
            }
        }

        const handleKeyUp = (event: KeyboardEvent) => {
            if (event.code === 'Space') {
                setSpaceHeldDown(false)
                setCursor('auto')
            }
        }

        window.addEventListener('keydown', handleKeyDown)
        window.addEventListener('keyup', handleKeyUp)

        return () => {
            window.removeEventListener('keydown', handleKeyDown)
            window.removeEventListener('keyup', handleKeyUp)
        }
    }, [])

    function handleMouseDown(e: React.MouseEvent) {
        if (spaceHeldDown) {
            e.preventDefault()
            setIsDragging(true)
            setStartX(e.clientX)
            setStartY(e.clientY)
            setScrollLeft(document.documentElement.scrollLeft || 0)
            setScrollTop(document.documentElement.scrollTop || 0)
        }
    }

    function handleMouseMove(e: React.MouseEvent) {
        if (!isDragging) return
        const x = e.clientX
        const y = e.clientY
        const dx = x - startX
        const dy = y - startY
        // Move at double speed
        document.documentElement.scrollLeft = scrollLeft - dx * 2
        document.documentElement.scrollTop = scrollTop - dy * 2
    }

    function handleMouseUp() {
        setIsDragging(false)
    }

    useEffect(() => {
        const header = document.querySelector('.navbar')
        if (header) {
            // @ts-ignore
            setHeaderHeight(header.offsetHeight)
        }
    }, [])

    useEffect(() => {
        if (fileName) {
            localStorage.setItem(LAST_OPEN_FILE_NAME_KEY, fileName)
        }
    }, [fileName])

    useEffect(() => {
        if (systemDirectiveHistoru) {
            localStorage.setItem(SYSTEM_DIRECTIVE_HISTORY_KEY, JSON.stringify(systemDirectiveHistoru))
        }
    }, [systemDirectiveHistoru])

    useEffect(() => {
        if (apiKey && apiKey.length > 0) {
            // No-op
        } else {
            // No api key, so show options
            dispatch(showOptionsDialog())
        }

        // Load last conversation if around or default data
        // Change to include last
        const lastFileName = localStorage.getItem(LAST_OPEN_FILE_NAME_KEY)
        if (lastFileName) {
            setFileName(lastFileName)
            setSelectedFile(lastFileName)
        } else {
            // Just go new
            const newFileName = datedFile()
            setFileName(newFileName)
            setSelectedFile(newFileName)
        }

        const allSaves = localStorage.getItem(SAVE_KEY)
        if (allSaves) {
            const saves = JSON.parse(allSaves)
            setFiles(saves)

            if (lastFileName) {
                const fileToOpen = saves[lastFileName]
                // Set app state after getting from local storage
                if (fileToOpen) {
                    setAppState(fileToOpen)
                }
            } else {
                // TODO: Consider default data
                // setAppState(defaultData)
                setAppState(NEW_APP_STATE())
            }
        } else {
            // TODO: Consider default data
            // setAppState(defaultData)
            setAppState(NEW_APP_STATE())
        }
    }, [])

    useEffect(() => {
        localStorage.setItem(API_KEY, apiKey)
    }, [apiKey])

    const handleApiKeyChange = (event: MouseEvent) => {
        // TODO: is this correct?
        setApiKey((event.target as HTMLInputElement).value)
    }

    const saveFiles = () => {
        // Save after every app state change
        setFiles(prevFiles => {

            if (fileName) {
                const newFiles = update(prevFiles, {
                    [fileName]: {$set: appState},
                    // newFiles[fileName] = appState
                    // userPrompts: {[guid]: {promptText: {$set: newPromptText}}},
                })

                localStorage.setItem(SAVE_KEY, JSON.stringify(newFiles))
                return newFiles
            } else {
                console.log('File name should never be undefined except at startup, this should fire once unless strict mode.')
                return prevFiles // No change so don't copy
            }
        })
    }

    useEffect(() => {
        saveFiles()
    }, [appState])
    const [debugMode, setDebugMode] = useState(false)

    // @ts-ignore
    const handleDebugModeChange = (event) => {
        setDebugMode(event.target.checked)
    }

    // @ts-ignore
    const handleCopyPrompt = (promptText, systemPromptGuid, responseIndex, parentGuid) => {
        setAppState(prevState => {
            // let stateCopy = deepCopy<AppState>(prevState)
            const newGuid = uuidv4()
            const newPrompt = makeNewPrompt()
            // Probably overkill to do these all, but I don't want to spend the time figuring out why this was all done twice
            // Still failing
            newPrompt.promptText = promptText

            // stateCopy.userPrompts[newGuid] = newPrompt
            if (!parentGuid) {
                return update(prevState, {
                    userPrompts: {[newGuid]: {$set: newPrompt}},
                    systemPrompts: {[systemPromptGuid]: {promptGuids: {$push: [newGuid]}}},
                })
                // Add to top level since no parent
                // stateCopy.systemPrompts[systemPromptGuid].promptGuids.push(newGuid)
            } else {
                return update(prevState, {
                    userPrompts: {
                        [newGuid]: {$set: newPrompt},
                        [parentGuid]: {responses: {[responseIndex]: {prompts: {$push: [newGuid]}}}},
                    },
                })
                // Add to parent responses!
                // stateCopy.userPrompts[parentGuid].responses[responseIndex].prompts.push(newGuid)
            }
            // return stateCopy
        })
    }

    const handleError = (error: HttpError) => {
        // TODO: You might need to fix your key! if 401, show options?
        console.log(error)
    }

    const handleChangePromptText = (guid: string, newPromptText: string) => {
        // best explanation https://stackoverflow.com/questions/43638938/updating-an-object-with-setstate-in-react
        setAppState(prevState => {
                return update(prevState, {
                        userPrompts: {[guid]: {promptText: {$set: newPromptText}}},
                    },
                )
                // stateCopy.userPrompts[guid].promptText = newPromptText
            },
        )
    }

    const totalTokens = Object.values(appState.userPrompts).reduce((acc, curr) => acc + curr.responses.reduce((costAccum, response) => costAccum + response.usage.totalTokens, 0), 0)
    const optionsDialogShowing = useAppSelector((state: RootState) => {
        return state.toolbar.optionsDialogShowing
    })
    return <div className="App"
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                style={{cursor}}
    >
        <WakeLock wakelockOn={isPlaying}/>
        {/*<div className="container">*/}
        <Navbar bg="dark" expand="true" expanded={false}
                fixed="top"
        >
            <Container fluid>
                <Button
                    variant="secondary"
                    onClick={() => {
                        setAppState(NEW_APP_STATE())
                        const newFileName = datedFile()
                        // TODO: do I need to update file names?
                        setFileName(newFileName)
                        setSelectedFile(newFileName)
                    }}>New
                </Button>
                <div className="separator"/>
                <FilePicker selectedFileName={selectedFile} possibleFileNames={Object.keys(files)}
                            handleOnButtonClicked={(fileName) => {
                                setShowRename(true)
                            }}
                            handleOnDropdownItemClicked={(selectedFileName) => {
                                setFileName(selectedFileName)
                                setSelectedFile(selectedFileName)
                                const newAppState = files[selectedFileName]
                                setAppState(newAppState)
                            }}/>
                <RenameModal show={showRename}
                             originalFilename={fileName}
                             handleOnRename={(newFilename: string) => {
                                 /**
                                  * Remove old element from saves
                                  * save new to new file name
                                  * Change file name
                                  */
                                 setFiles(prevFiles => {
                                     // const newFiles = deepCopy(prevFiles)
                                     if (fileName) {
                                         const file = prevFiles[fileName] // File to move

                                         const newFiles = update(prevFiles, {
                                             // {$unset: [fileName]}, // Delete old one, not sure how to do in here?
                                             [newFilename]: {$set: file},
                                         })
                                         // Delete old
                                         // Set new
                                         // const file = newFiles[fileName]
                                         delete newFiles[fileName]
                                         // newFiles[newFilename] = file
                                         setFileName(newFilename)
                                         setSelectedFile(newFilename)

                                         localStorage.setItem(SAVE_KEY, JSON.stringify(newFiles))
                                         return newFiles
                                     } else {
                                         console.log('Did not expect an undefined file from rename')
                                         return prevFiles
                                     }
                                 })
                             }} handleOnClose={() => setShowRename(false)}/>
                <div className="spacer"></div>
                {localOnly() &&
                    <>
                        <Button onClick={() => {
                            setShowGraph(prevState => !prevState)
                        }}>Graph</Button>
                        <Counter/>
                    </>
                }
                <div className="separator"/>
                <div>
                    {totalTokens} tokens used
                </div>
                <div className="separator"/>

                <div>
                    {((totalTokens / 1000) * .002).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        },
                    )}
                </div>
                <div className="separator"/>

                <SupportButton/>
                <div className="separator"/>

                <Button variant="secondary" onClick={() => dispatch(showOptionsDialog())}>Options</Button>
            </Container>
        </Navbar>
        <OptionsDialog isOpen={optionsDialogShowing}
                       onClose={() => {
                           dispatch(hideOptionsDialog())
                       }}
                       apiKey={apiKey}
                       debugMode={debugMode}
                       handleApiKeyChange={handleApiKeyChange}
                       handleDebugModeChange={handleDebugModeChange}
        />
        {showGraph && <NodeView name={'hi'} color={''} appState={appState}/>}
        <div className="content" style={{marginTop: headerHeight}}>
            <div style={{display: 'flex', flexDirection: 'row'}}>
                {
                    Object.entries(appState.systemPrompts).map(([systemPromptGuid, systemPrompt]) => {
                        return <div key={systemPromptGuid}
                        >
                            <SystemDirectiveInput
                                minWidth="400px"
                                handleOnDelete={(promptToDelete) => {
                                    setSystemDirectiveHistory(prevState => {
                                        const indexOf = prevState.directivesText.indexOf(promptToDelete)
                                        return update(prevState, {
                                            directivesText: {$splice: [[indexOf, 1]]},
                                        })
                                    })
                                }}
                                prompt={systemPrompt}
                                guid={systemPromptGuid}
                                appState={appState}
                                disabled={disabledSystemDirectives[systemPromptGuid]}
                                systemDirectiveHistory={systemDirectiveHistoru}
                                handleOnPromptChanged={(newPromptText: string, guid: string) => {
                                    setAppState(prevState => {
                                        return update(prevState, {
                                            systemPrompts: {
                                                [guid]: {
                                                    promptText: {$set: newPromptText},
                                                },
                                            },
                                        })
                                    })
                                }
                                }
                                handleOnCopyClick={(promptText: string) => {
                                    setAppState(prevState => {
                                        const newSystemPrompt = makeNewSystemPrompt(promptText)
                                        const newSystemPromptGuid = uuidv4()
                                        const newPrompt = makeNewPrompt()
                                        const newPromptGuid = uuidv4()
                                        newSystemPrompt.promptGuids.push(newPromptGuid)

                                        return update(prevState, {
                                            systemPrompts: {
                                                [newSystemPromptGuid]: {$set: newSystemPrompt},
                                            },
                                            userPrompts: {
                                                [newPromptGuid]: {$set: newPrompt},
                                            },
                                        })
                                    })
                                }}/>
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                {
                                    systemPrompt.promptGuids.map((guid: string) => {
                                        return <PromptBlock
                                            minWidth="400px"
                                            key={guid}
                                            guid={guid}
                                            appState={appState}
                                            handleIsPlaying={isPlaying => {
                                                setIsPlaying(isPlaying)
                                            }
                                            }
                                            isPlaying={isPlaying}
                                            handleOnSubmitClick={() => {
                                                // Lock the editing, lock the
                                                setDisabledSystemDirectives(prevState => {
                                                    return update(prevState, {
                                                        [systemPromptGuid]: {$set: true},
                                                    })
                                                })
                                                setSystemDirectiveHistory((prevState: SystemDirectiveHistory) => {
                                                    const promptText = appState.systemPrompts[systemPromptGuid].promptText
                                                    if (!prevState.directivesText.includes(promptText)) {
                                                        return update(prevState, {
                                                            directivesText: {$push: [promptText]},
                                                        })
                                                    } else {
                                                        // Already included, so no update
                                                        return prevState
                                                    }

                                                })
                                            }
                                            }
                                            messages={createSystemMessage(systemPrompt.promptText)} // Top level so never messages
                                            handleCopyPrompt={handleCopyPrompt}
                                            // handleAddNewResponse={handleAddNewResponse} // GUID, newResponse
                                            handleChangePromptText={handleChangePromptText} // GUID, newText
                                            debug={debugMode}
                                            systemPromptGuid={systemPromptGuid}
                                            // TODO: clean this up
                                            handleError={(error: any) => handleError(error)}
                                            handleShowOptions={() => {
                                                dispatch(showOptionsDialog())
                                            }}
                                            apiKey={apiKey} // TODO: use context
                                            handleResponsePiece={(guid: string, partialMessage: string, responseGuid: string, usage: Usage, responseIndex?: number) => {
                                                setAppState(prevState => {
                                                    // let newState = deepCopy<AppState>(prevState)

                                                    const existingResponseIndex = prevState.userPrompts[guid].responses.findIndex((item: Response) => item.uuid === responseGuid)
                                                    if (existingResponseIndex === -1) {
                                                        // Add new one since this is the first

                                                        const newGuid = uuidv4()

                                                        const newResponse = new Response(partialMessage, {}, [newGuid], responseGuid)
                                                        newResponse.usage = usage

                                                        const newState = update(prevState, {
                                                            userPrompts: {
                                                                [newGuid]: {$set: makeNewPrompt()},
                                                                [guid]: {responses: {$push: [newResponse]}},
                                                            },
                                                        })
                                                        // newState.userPrompts[newGuid] = makeNewPrompt()
                                                        // newState.userPrompts[guid].responses = [...newState.userPrompts[guid].responses, newResponse]
                                                        return newState
                                                    } else {
                                                        // Already exists so change it
                                                        const newState = update(prevState, {
                                                            userPrompts: {
                                                                [guid]: {
                                                                    responses: {
                                                                        [existingResponseIndex]: {
                                                                            message: {$set: partialMessage},
                                                                            usage: {$set: usage},
                                                                        },
                                                                    },
                                                                },
                                                            },
                                                        })

                                                        // newState.userPrompts[guid].responses[existingResponseIndex].message = partialMessage
                                                        // newState.userPrompts[guid].responses[existingResponseIndex].usage = usage
                                                        return newState
                                                    }
                                                })

                                            }
                                            }
                                        />
                                    })}
                            </div>
                        </div>
                    })
                }
            </div>
            <TipRequest/>

        </div>
        {/*</div>*/}
    </div>
}

export default App
