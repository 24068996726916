import React from 'react'
import Button from 'react-bootstrap/Button'

function SupportButton(): JSX.Element {
    function handleClick(): void {
        window.location.href = 'mailto:creativerse@mikedg.com'
    }

    return (
        <Button variant="primary" onClick={handleClick}>
            Support
        </Button>
    )
}

export default SupportButton
