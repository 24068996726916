export enum Role {
    Assistant = 'assistant',
    User = 'user',
    System = 'system'
}

export class Message {
    role: Role;
    content: string;

    constructor(role: Role, content: string) {
        this.role = role;
        this.content = content;
    }
}
// export {Role: Role, message: Message}