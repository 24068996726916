import React from 'react'

import './OptionsDialog.css'
import {Alert} from 'react-bootstrap'

// @ts-ignore
const OptionsDialog = ({isOpen, onClose, debugMode, apiKey, handleDebugModeChange, handleApiKeyChange}) => {
    if (!isOpen) {
        return null
    }

    return (
        <div className="options-dialog-overlay">
            <div className="options-dialog-container">
                <h2>Options</h2>
                <div>
                    <label htmlFor="debug-checkbox">Enable debug mode:</label>
                    <input
                        type="checkbox"
                        id="debug-checkbox"
                        checked={debugMode}
                        onChange={handleDebugModeChange}
                    />
                    <br/>
                    Open AI API Key:
                    <input type="text"
                           value={apiKey}
                           onChange={handleApiKeyChange}
                    />
                </div>
                {apiKey.length < 10 ?
                    <Alert variant={'danger'}>
                        You need an API key from Open AI. Grab one <Alert.Link
                        href="https://platform.openai.com/account/api-keys">here!</Alert.Link>.
                    </Alert> : null}

                <button onClick={onClose}>Close</button>
            </div>
        </div>
    )
}

export default OptionsDialog
