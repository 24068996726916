import {configureStore} from '@reduxjs/toolkit'
import counterReducer from './counterSlice'
import toolbarReducer from './toolbarSlice'

// import counterReducer from './counterSlice'

// ...

const store = configureStore({
    reducer: {
        counter: counterReducer,
        toolbar: toolbarReducer,
    },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store