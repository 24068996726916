import React from 'react'
import {Usage} from '../data/Usage'
import {Accordion, Card, useAccordionButton} from 'react-bootstrap'
import './UsageDetails.css'

interface Props {
    usage: Usage;
}

// @ts-ignore
function AccordionButton({children, eventKey, style}) {
    const decoratedOnClick = useAccordionButton(eventKey, () => {
        },
    )

    return (
        <button
            type="button"
            onClick={decoratedOnClick}
            style={style}
        >
            {children}
        </button>
    )
}

const UsageDetails: React.FC<Props> = ({usage}) => {
    const {promptTokens, completionTokens, totalTokens} = usage
    const cost = ((totalTokens / 1000) * .002).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 4, // show at least 2 decimal places
            maximumFractionDigits: 5, // show up to 5 decimal places
        }
    )

    return (
        <Accordion
        >
            <Card style={{
                backgroundColor: 'transparent', /* Background color */
                padding: '6px', /* Padding */
                // outline: '#000000'
            }}>
                <Card.Header style={{
                    backgroundColor: totalTokens > 3500 ? 'red' : 'transparent', /* Background color */
                    padding: '6px', /* Padding */
                    // outline: '#000000'
                }}
                >
                    <AccordionButton eventKey="0" style={{backgroundColor: 'transparent', width: '100%'}}
                    >{promptTokens} + {completionTokens} = {totalTokens} {cost}</AccordionButton>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                    <Card.Body style={{
                        color: '#ffffff', /* Text color */
                        backgroundColor: 'transparent', /* Background color */
                        padding: '6px' /* Padding */
                    }}
                    > These are all estimates. Do not rely on these numbers to be accurate.
                        <div>{`Prompt Tokens: ${promptTokens}`}</div>
                        <div>{`Completion Tokens: ${completionTokens}`}</div>
                        <div>{`Total Tokens: ${totalTokens}`}</div>
                        <div>{`Estimated Cost: ${cost}`}
                        </div>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    )
}

export default UsageDetails
